var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("the-wrapper-wallet", {
    attrs: {
      "total-left-col-items": 2,
      "has-draggable": false,
      "total-right-col-items": 2,
    },
    scopedSlots: _vm._u([
      {
        key: "leftColItem1",
        fn: function () {
          return [_c("module-balance")]
        },
        proxy: true,
      },
      {
        key: "leftColItem2",
        fn: function () {
          return [_c("module-tokens")]
        },
        proxy: true,
      },
      {
        key: "rightColItem1",
        fn: function () {
          return [_c("module-swap-rates")]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }